import moment from 'moment';

export default {
  columnLabels: {
    CLIENT_NAME: 'Client Name (QB)',
    TOTAL_FROM_2021: 'Total Revenue (from January 1, 2021)',
    BUDGET_FROM_2021: 'Total Budget (from January 1, 2021)'
  },
  monthsLabels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  industries: [
    {
      label: 'Legal',
      value: 'legal'
    },
    {
      label: 'Healthcare',
      value: 'healthcare'
    },
    {
      label: 'Home Services',
      value: 'home_services'
    },
    {
      label: 'Financial',
      value: 'financial'
    },
    {
      label: 'Transportation',
      value: 'transportation'
    },
    {
      label: 'Ecommerce/Catalog',
      value: 'ecommerce_catalog'
    },
    {
      label: 'Others',
      value: 'others'
    }
  ],
  getIndustryLabel(industry_value) {
    return this.industries.find((i) => i.value === industry_value)?.label || null
  },
  priceFormatter(price, allowNull = false) {
    if (price === 'inapplicable') {
      return price;
    }
    const num = Number(price)
    if (isNaN(num) || num === 0) {
      if (allowNull) {
        return '$0'
      }
      return ''
    }
    return '$' + num.toLocaleString('en', { maximumFractionDigits: 2 })
  },
  numberFormatter(number, allowNull = false) {
    const num = Number(number)
    if (isNaN(num) || num === 0) {
      if (allowNull) {
        return '0'
      }
      return ''
    }
    return num.toLocaleString('en', { maximumFractionDigits: 2 })
  },
  percentFormatter(percent, allowNull = false) {
    if (!percent) {
      return percent
    }
    return `${percent}%`
  },
  dateFormatter(date, time = false) {
    if (!date) return '--/--';
    if (date === 'inapplicable') return 'inapplicable';
    const originDate = new Date(date)
    let formatter = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
    if (time) {
      formatter = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
    }
    return formatter.format(originDate)
  },
  monthYearStringToLabels(string) {
    const [month, year] = string.split('_')
    const monthIndex = parseInt(month, 10) - 1
    return `${this.monthsLabels[monthIndex]} ${year}`
  },
  dayMonthYearStringToLabels(string) {
    const [day, month, year] = string.split('_')
    const monthIndex = parseInt(month, 10) - 1
    return `${day} ${this.monthsLabels[monthIndex]} ${year}`
  },
  getModifiedFiltersLabels(filters) {
    return filters.map((filter) => {
      // return filter.type ? `${filter.type} (${filter.count})` : `Blank (${filter.count})`
      return filter.type
    })
  },
  dateMonthToSimple(month) {
    let simpleMonth = +month + 1;
    let monthString = '';
    if (month < 9) {
      monthString = `0${simpleMonth}`;
    } else {
      monthString = `${simpleMonth}`;
    }
    return monthString;
  },
  getLastDayOfMonth(year, month) {
    const date = moment.utc([year, month]);
    return date.endOf('month').date();
  },
  replaceBase64WithImgTag(text) {
    if (!text) {
      return '';
    }
    const base64Regex = /Screenshot:\s*([A-Za-z0-9+/=]+)/g;
    const replaceWithImgTag = (match, base64) => {
      return `<img src="data:image/png;base64,${base64}" alt="Base64 Image">`;
    };
    const result = text.replace(base64Regex, replaceWithImgTag);
    return result;
  }
}
